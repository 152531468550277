import './ChatType.css'
import build_profile from "../../img/chat/build_profile.svg";
import chat_with_aspira from "../../img/chat/chat_with_aspira.svg";
import { useShareWindow } from "../../context/ShareWindowContext.js";



const ChatType = () => {
  const { changeSelectedType } = useShareWindow();
  return (
    <div className="chat-type-container">
      <div className="chat-type-inner">
        <div className="chat-type-items">
          <div className="chat-type-item-wrapper">
            <div className="chat-type-item" onClick={() => changeSelectedType('type1')}>
              <div className='chat-type-item-img'>
                <img src={chat_with_aspira} alt="" />
              </div>
              <p>Chat with Aspira</p>
            </div>
          </div>
          <div className="chat-type-item-wrapper">
            <div className="chat-type-item" onClick={() => changeSelectedType('type2')}>
              <div className='chat-type-item-img'>
                <img src={build_profile} alt="" />
              </div>
              <p>Build your profile</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChatType