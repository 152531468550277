import React, { useState, useEffect, useRef } from "react";
import ReactDOMServer from "react-dom/server";

import axios from "axios";
import voiceLogo from "../../img/mic_icon.svg";
import notificationSound from "../../sounds/notification sound.mp3";
import emojiStrip from "emoji-strip";
import mixpanel from 'mixpanel-browser';
import { useShareWindow } from "../../context/ShareWindowContext.js";
import "./Form.css";
import Chat from "../Chat/Chat";
import ChatInput from "../ChatInput/ChatInput";
import { useParams } from "react-router-dom";
import ChatType from "../ChatType/ChatType.js";




/*for the localhost:
const AI_API_URL = 'https://aspirations.stageyou.com:5000/api/ai';
const VOICE_RECOGNITION_API_URL = 'https://aspirations.stageyou.com:5000/api/voice-recognition';

for the server:
const AI_API_URL = 'https://aspirations.stageyou.com:5000/api/ai';
const VOICE_RECOGNITION_API_URL = 'https://aspirations.stageyou.com:5000/api/voice-recognition';
*/
const VOICE_RECOGNITION_API_URL =
  "https://aspirations.stageyou.com:5000/api/voice-recognition";
const AUDIO_SYNTHESIS_API_URL =
  "https://aspirations.stageyou.com:5000/api/convert-text-to-speech";
const AUTOCOMPLETE_API_URL = "https://aspirations.stageyou.com:5000/api/autocomplete";
const VISION = "https://aspirations.stageyou.com:5000/upload-image";
const CONVERSATION_STARTER = "https://aspirations.stageyou.com:5000/conversation-starter";
const CONV_RESPONSE = "https://aspirations.stageyou.com:5000/chat-starter-response";

const axiosInstance = axios.create();
const axiosVoiceRecognitionInstance = axios.create();

mixpanel.init('f3bc0a936d16d0fd8b1da0c66e4ae0b0', { debug: true, track_pageview: true, persistence: 'localStorage' });


function Form({
  setReturnBar,
  backgroundMp,
  setCenterHome,
}) {
  const [selectedOption, setSelectedOption] = useState("option1");
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [isRecording, setIsRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [responseTexts, setResponseTexts] = useState(["", "", "", ""]);
  const [isSelectSuggest, setSelectSuggest] = useState(true);
  const [boxSuggession, setBoxSuggession] = useState("transparent");
  const [conversationHistory, setConversationHistory] = useState([]);
  const { session: urlSession } = useParams();
  const [isPopupOpen, setIsPopupOpen] = useState(false);





  const { currentlyPlayingIndex, setCurrentlyPlayingIndex, setMinMaxHeight, setChatStart, selectedType,setSelectedType,
    chatSession, setChatSession, input, setInput,
    isVoiceSynthesisInProgress, setIsVoiceSynthesisInProgress,
    isAutoPlayInProgress, setIsAutoPlayInProgress, isSubmitting, setIsSubmitting,
    disableSendButton, setDisableSendButton,
    disableMicButton, setDisableMicButton, setIsVoiceSynthesisActive, setIsAudioPlaying, handleStopButtonClick,
    audioData, setAudioData, playAudio, textResponse, setTextResponse, userHistory, setUserHistory, showDropdown, setShowDropdown, imagePreview, setImagePreview, isStyled, setIsStyled
  } = useShareWindow();

  const textCount = input.replace(/\s+/g, '').length;
  const textareaRef = useRef(null);
  const outputContainerRef = useRef(null);
  const outputinnerContainerRef = useRef(null);

  const dropdownRef = useRef(null);

  const SECRET_KEY =process.env.REACT_APP_SECRET_KEY;

  // Define AI_API_URL based on selectedType
  const AI_API_URL =
     selectedType === 'type1' 
    ? 'https://aspirations.stageyou.com/backend/api/chat' 
    : selectedType === 'type2' 
      ? 'https://aspirations.stageyou.com/backend/api/profile-builder' 
      : '';

  // Example function to change selectedType


  useEffect(() => {
    // Example API call when selectedType changes
    if (AI_API_URL) {
      fetch(AI_API_URL, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-Secret-Key': SECRET_KEY
        }
      })
        .then(response => response.json())
        .then(data => {
          console.log('API response:', data);
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
    }
  }, [AI_API_URL]);

  useEffect(() => {
    if (urlSession) {
      // If a session ID is present in the URL, use it
      setChatSession(urlSession);

      // Fetch conversation history for the current chat session
      axios
        .get(`https://aspirations.stageyou.com/backend/api/conversation-history/${urlSession}`, {
          headers: { 'X-Secret-Key': SECRET_KEY }
        })
        
        .then((response) => {
          // Update conversation history state
          setConversationHistory(response.data);
        })
        .catch((error) => {
          console.error("Error fetching conversation history:", error);
        });
    } else {
      // If no session ID in the URL, fetch a new session ID when the component mounts
      axios
        .get("https://aspirations.stageyou.com/backend/api/generate-session", {
          headers: { 'X-Secret-Key': SECRET_KEY }
        })
        .then((response) => {
          const newChatSession = response.data.chatSession;
          setChatSession(newChatSession);
          // Store the new session ID in localStorage
          localStorage.setItem("chatSession", newChatSession);
        })
        .catch((error) => {
          console.error("Error generating session:", error);
        });
    }
  }, [urlSession]); // Run the effect when the URL session changes

  // Construct the unique link for the current session

  useEffect(() => {
    adjustTextareaHeight();
  }, [input]);

  useEffect(() => {
    if (!(selectedOption === "option1")) {
      setChatStart(true);
    }
  }, [selectedOption, backgroundMp]);

  useEffect(() => {
    changeSelectedOption("option1");
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [userHistory, showLoadingIndicator]);

  const scrollToBottom = () => {
    const parentElement = document.querySelector(".q-and-a-container");
    const outputContainer = outputContainerRef.current;
    const outputInner = outputinnerContainerRef.current;

    if (outputContainer) {
      const lastChild = outputContainer.lastChild;
      const childElements = parentElement.querySelectorAll(".main-q-a-box");

      if (lastChild && childElements.length > 0) {
        const containerHeight = outputContainer.clientHeight;
        const lastChildHeight = lastChild.clientHeight;
        const scrollHeight = outputContainer.scrollHeight;
        const innerHeight = outputInner.clientHeight;
        const lastChilds = childElements[childElements.length - 1];
        const lastChildHei = lastChilds.getBoundingClientRect().height;
        if (lastChildHei > containerHeight - 45) {
          outputContainer.scrollTop =
            scrollHeight -
            (containerHeight + 80) -
            (lastChildHei - (containerHeight - 50));
        } else {
          if (scrollHeight - containerHeight <= lastChildHeight) {
            outputContainer.scrollTop = scrollHeight - (containerHeight - 50);
          } else {
            lastChild.scrollIntoView({ behavior: "smooth", block: "end" });
          }
        }
      }
    }
  };


  const handleSubmit = async (e) => {
    temporarySendMessege(input);

    setInput("");

    e.preventDefault();

    if (!input.trim()) {
      return;
    }

    try {
      setIsSubmitting(true);
      setDisableSendButton(true);
      setDisableMicButton(true);
      setIsVoiceSynthesisActive(true);
      setShowLoadingIndicator(true);
      setChatStart(true);
      setSelectSuggest(false);
      mixpanel.track('Query Sumbitted', { 'Button Type': 'querysumbitted' })

      // Prepare the conversation history with system and user messages
      const updatedConversation = [
        ...userHistory.flatMap((entry) => [
          { role: "user", content: entry.input },
          { role: "assistant", content: entry.response },
        ]),
        { role: "user", content: input },
      ];
      const res = await axiosInstance.post(AI_API_URL, {
        conversations: updatedConversation,
        selectedOption,
        chatSession,
      }, {
        headers: { 'X-Secret-Key': SECRET_KEY }
      });

      const textResponse = res.data.completion;

      removeElementById();

      playNotificationSound();
      const textResponseWithoutEmojis = emojiStrip(textResponse);

      setTextResponse(textResponseWithoutEmojis);
      const styledHtmlContent = res.data.completion.replace(
        regex,
        '<span class="bold-text">$1</span>'
      );
      const newEntry = {
        input,
        response: styledHtmlContent,
      };
      setUserHistory([...userHistory, newEntry]);

      triggerVoiceSynthesis(textResponseWithoutEmojis);

      setIsSubmitting(false);
      setDisableSendButton(false);
      setDisableMicButton(false);
      setIsVoiceSynthesisActive(false);
      setShowLoadingIndicator(false);
    } catch (error) {
      console.error(error);
      setIsSubmitting(false);
      setDisableSendButton(false);
      setDisableMicButton(false);
      setIsVoiceSynthesisActive(false);
      setShowLoadingIndicator(false);
    }
  };

  const removeElementById = () => {
    const elementToRemove = document.getElementById("temp-q-msg");

    if (elementToRemove) {
      elementToRemove.remove();
    } else {
      console.log("Element not found.");
    }
  };

  const temporarySendMessege = (transcription, input) => {
    const parentElement = document.querySelector(".q-and-a-container");
    const childElements = parentElement.querySelectorAll(".main-q-a-box");
    const lastChild = childElements[childElements.length - 1];
    const index = childElements.length;

    const newElement = (
      <div id="temp-q-msg" className="main-q-a-box" key={index}>
        <div className="client-msg-bubble">
          <h2 className="client-questions">
            {transcription}
            {input}
          </h2>
        </div>
      </div>
    );

    const htmlString = ReactDOMServer.renderToStaticMarkup(newElement);

    if (index === 0) {
      parentElement.insertAdjacentHTML("beforeend", htmlString);
    } else if (index > 0) {
      lastChild.insertAdjacentHTML("afterend", htmlString);
    }
  };

  const triggerVoiceSynthesis = async (textResponse, isAutoPlay) => {
    try {
      setIsVoiceSynthesisInProgress(true);

      const audioRes = await axiosInstance.post(AUDIO_SYNTHESIS_API_URL, {
        text: textResponse,

      });

      // Save the audio URL for later playback along with the corresponding text
      const audioUrl = audioRes.data.audioUrl;



      setAudioData((prevAudioData) => ({
        ...prevAudioData,
        [textResponse]: audioUrl,
      }));
      setIsVoiceSynthesisInProgress(false);
    } catch (error) {
      console.error(error);
      setIsVoiceSynthesisInProgress(false);
    }
  };

  const handleMicButtonClick = async () => {
    if (!isRecording && !isSubmitting && !isAutoPlayInProgress) {
      setIsRecording(true);
      mixpanel.track('Voice Recognition Used', {'Button Type': 'voicerecognition'})
      try {
        let stream;
        
        // Check if it's iOS
        const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
        if (isIOS) {
          stream = await navigator.mediaDevices.getUserMedia({
            audio: {
              channelCount: 1,
              echoCancellation: true,
              sampleRate: 44100,
            },
          });
        } else {
          stream = await navigator.mediaDevices.getUserMedia({
            audio: true,
          });
        }
  
        const audioContext = new (window.AudioContext || window.webkitAudioContext)();
        const source = audioContext.createMediaStreamSource(stream);
        const analyser = audioContext.createAnalyser();
        source.connect(analyser);
        analyser.fftSize = 2048;
        const dataArray = new Uint8Array(analyser.fftSize);
  
        const recorder = new MediaRecorder(stream);
        setMediaRecorder(recorder);
  
        const chunks = [];
        let silenceDetected = true;
        const silenceThreshold = 0.01;
        const silenceDuration = 1000;
  
        const checkForSilence = () => {
          analyser.getByteTimeDomainData(dataArray);
          const isSilent = dataArray.every(value => Math.abs(value - 128) < (128 * silenceThreshold));
          if (!isSilent) {
            silenceDetected = false;
          }
          if (silenceDetected) {
            setTimeout(checkForSilence, silenceDuration);
          }
        };
        
        checkForSilence();
  
        recorder.ondataavailable = (event) => {
          if (event.data.size > 0) {
            chunks.push(event.data);
          }
        };
  
        recorder.onstop = () => {
          if (!silenceDetected) {
            const audioBlob = new Blob(chunks, { type: "audio/wav" });
            sendAudioToBackend(audioBlob);
          }
          setIsRecording(false);
          setMediaRecorder(null);
        };
  
        recorder.start();
        silenceDetected = true;
      } catch (error) {
        console.error("Error accessing microphone:", error);
        setIsRecording(false);
      }
    } else if (mediaRecorder) {
      mediaRecorder.stop();
      setMediaRecorder(null);
      setIsRecording(false);
    }
  };
  
  const sendAudioToBackend = async (audioBlob) => {
    try {
      const formData = new FormData();
      formData.append("audioFile", audioBlob);
  
      const res = await axiosVoiceRecognitionInstance.post(
        VOICE_RECOGNITION_API_URL,
        formData
      );
      const { transcription } = res.data;
  
      temporarySendMessege(transcription);
  
      setShowLoadingIndicator(true);
  
      if (transcription.trim().length > 0) {
        const updatedConversation = [
          ...userHistory.flatMap((entry) => [
            { role: "user", content: entry.input },
            { role: "assistant", content: entry.response },
          ]),
          { role: "user", content: transcription },
        ];
  
        const openaiRes = await axiosInstance.post(AI_API_URL, {
          conversations: updatedConversation,
          selectedOption,
          chatSession,
        });
        removeElementById();
  
        playNotificationSound();
        const textResponseWithoutEmojis = emojiStrip(openaiRes.data.completion);
        setTextResponse(textResponseWithoutEmojis);
  
        const styledHtmlContent = openaiRes.data.completion.replace(
          regex,
          '<span class="bold-text">$1</span>'
        );
        const newEntry = {
          input: transcription,
          response: styledHtmlContent,
        };
        setUserHistory([...userHistory, newEntry]);
        setInput("");
  
        setDisableSendButton(false);
        setDisableMicButton(false);
  
        triggerVoiceSynthesis(textResponseWithoutEmojis);
  
        setShowLoadingIndicator(false);
      } else {
        console.log("Empty transcription. Not sending to OpenAI.");
        setShowLoadingIndicator(false);
      }
    } catch (error) {
      console.error(error);
      setShowLoadingIndicator(false);
      setDisableSendButton(false);
      setDisableMicButton(false);
    }
  };

  const handleOptionChange = (e) => {
    const option = e.target.value;
    setSelectedOption(option);
    setInput("");
  };



  const handleInputChange = (e) => {
    const newInput = e.target.value;
    setInput(newInput);
    //fetchAutocompleteSuggestions(newInput);

    // Update showDropdown state to control visibility
    setShowDropdown(newInput !== "");

    adjustTextareaHeight();
    // Opacity change there is text in the textarea
  };

  const getLetterCount = () => {
    return input.length;
  };


  const getStyles = () => {
    const opacity = input === "" ? 0.6 : 1;
    const boxShadow = input === "" ? "none" : "";

    return { opacity, boxShadow };
  };


  //suggestions selector
  const handleSuggestionClick = (selectedSuggestion) => {
    setInput(selectedSuggestion);
    setSuggestions([]); // Clear the suggestions list
  };



  const [disableFunction, setDisableFunction] = useState(false);

  const handleKeyDown = (e) => {
    if (textCount > 0) {

      if (!disableFunction && e.key === "Enter") {
        handleSubmit(e);
      }
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setDisableFunction(window.innerWidth < 767);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);


  const playNotificationSound = () => {
    const audio = new Audio(notificationSound);

    audio.play();
    setSelectedImage(null);
  };

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    textarea.style.height = "44px";

    const newHeight = Math.min(Math.max(textarea.scrollHeight, 44), 130);
textarea.style.height = `${newHeight}px`;
    setMinMaxHeight(newHeight - 44)
  };

  const handleKeyUp = (e) => {
    if (e.key === "Backspace") {
      adjustTextareaHeight();
    }
  };

  const getPlaceholderText = () => {
    switch (selectedOption) {
      case "option1":
        return "Type your questions here";
      case "option2":
        return "What are your symptoms";
      case "option3":
        return "Enter your health report";
      case "option4":
        return "Ask me about healthy eating";
      case "option6":
        return "How can I help you be mindful?";
      case "option7":
        return "How can I help you sleep better?";
      case "option8":
        return "How can I help you stay fit?";
      case "option9":
        return "Investors, ask me anything?";
      case "option11":
        return "How can I help reduce stress?";
      default:
        return "Enter your input";
    }
  };




  useEffect(() => {
    adjustTextareaHeight();
    window.addEventListener("click", handleWindowClick);

    const playResponseVoice = async () => {
      try {
        if (textResponse) {
          // Play the audio URL if it exists
          if (audioData[textResponse]) {
          } else {
            await triggerVoiceSynthesis(textResponse, true);
            setIsAudioPlaying(true);
            const currentlyPlayingAudio = userHistory.length;
            setCurrentlyPlayingIndex(currentlyPlayingAudio);
            setIsAutoPlayInProgress(false); // Reset auto-play in progress
            setIsAudioPlaying(false);
          }
        }
      } catch (error) {
        console.error(error);
      }
    };

    playResponseVoice();

    return () => {
      window.removeEventListener("click", handleWindowClick);
    };
  }, [textResponse, audioData, userHistory]);

  const handleWindowClick = () => {
    setShowDropdown(false);
  };


  const htmlContent = ``;
  const regex = /\*\*(.*?)\*\*/g;
  const styledHtmlContent = htmlContent.replace(
    regex,
    '<span class="bold-text">$1</span>'
  );

  //conversation starter
  const changeSelectedOption = async (option = "option1") => {
    try {
      // Send the selected option to the backend
      const res = await axiosInstance.post(CONVERSATION_STARTER, {
        selectedOption: option,
      });
      //setIsSubmitting(true);
      setResponseTexts(res.data.responses);
    } catch (error) {
      console.error("Error sending option to backend:", error);
    }
  };

  const handlechtst1ButtonClick = async () => {
    try {
      await changeSelectedOption();
      // Send the button text to the response endpoint
      const response = await axios.post(CONV_RESPONSE, {
        buttonText: responseTexts,
      });

      // Get the response back from the backend
      const { conversation: backendResponse } = response.data;

    } catch (error) {
      console.error("Error sending button text to backend:", error);
    }
  };

  //option handling

  const inputFileRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(file.name);
      // Create a FormData object to send the image

      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };

      reader.readAsDataURL(file);
      const formData = new FormData();
      formData.append("image", file);

      // Send the image to the server using an HTTP POST request
      axios
        .post(VISION, formData)
        .then((response) => {
          // Handle the server's response here, which may contain the local file path where the image is saved.
        })
        .catch((error) => {
          console.error("Error uploading image:", error);
        });
    }
  };

  const handleImageUpload = () => {
    if (inputFileRef.current) {
      inputFileRef.current.value = null;
      // Reset selected image and preview
      inputFileRef.current.click();

      setSelectedImage(null);
      setImagePreview(null);
    }
  };

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };


  const style = {
    display: selectedType ? 'none' : 'block'
  };

  return (
    <div
      className={`main-background-container background-video    ${!isStyled ? 'start-main-background-container' : ''}`}
    // style={{ backgroundImage: `url(${backgroundVideo})` }}
    >
      <div className={`main-inner-container container   ${!isStyled ? 'start-main-inner-container' : ''}`}>
        <form
          className={`kps-chat-form form  ${!isStyled ? 'start-form' : ''}`}
          onSubmit={handleSubmit}
        // style={marginStyle}
        //  style={/iPhone|iPod|iPad/i.test(navigator.userAgent)? { margin: "0 0 80px 0" } : { margin: "0" }}
        >


          {isSelectSuggest ? (
            <>
              <div className="chat-suggesst-title  "></div>
            </>
          ) : null}

          <Chat
            userHistory={userHistory}
            currentlyPlayingIndex={currentlyPlayingIndex}
            isVoiceSynthesisInProgress={isVoiceSynthesisInProgress}
            handleStopButtonClick={handleStopButtonClick}
            playAudio={playAudio}
            showLoadingIndicator={showLoadingIndicator}
            handlechtst1ButtonClick={handlechtst1ButtonClick}
            setInput={setInput}
            responseTexts={responseTexts}
            selectedOption={selectedOption}
          />

          <div className="mobile-voice-container">
            <button
              type="button"
              className=" ripple-btn"
              onClick={handleMicButtonClick}
              disabled={isSubmitting}
            >
              <img
                className="chat-logo-image "
                src={voiceLogo}
                alt="Microphone"
              />
            </button>
            <span className="mobile-divider"></span>
          </div>

          <div className="chat-type-wrapper"  style={style}>
          <ChatType/>

          </div>

          <div className={`input-container   ${!isStyled ? 'start-input-container' : ''} `}>
          
         
            <ChatInput
              setReturnBar={setReturnBar}
              textareaRef={textareaRef}
              input={input}
              setInput={setInput}
              getLetterCount={getLetterCount}
              handleInputChange={handleInputChange}
              handleKeyDown={handleKeyDown}
              handleKeyUp={handleKeyUp}
              isSubmitting={isSubmitting}
              disableMicButton={disableMicButton}
              getPlaceholderText={getPlaceholderText}
              suggestions={suggestions}
              showDropdown={showDropdown}
              handleSuggestionClick={handleSuggestionClick}
              disableSendButton={disableSendButton}
              handleMicButtonClick={handleMicButtonClick}
              dropdownRef={dropdownRef}
              setSelectedImage={setSelectedImage}
              getStyles={getStyles}
              userHistory={userHistory}
              isRecording={isRecording}
              handleImageChange={handleImageChange}
              inputFileRef={inputFileRef}
              handleImageUpload={handleImageUpload}
              selectedImage={selectedImage}
              imagePreview={imagePreview}
              boxSuggession={boxSuggession}
              selectedOption={selectedOption}
              setCenterHome={setCenterHome}
              setImagePreview={setImagePreview}
            />
            <div className=" ">
              {<div className="answer_policy " >
                <div>
                  AI Powered by <a href="https://dejavu.quest/" className="answer_policy_link">DejaVu Quest, Inc.</a>
                </div>
              </div>}
            </div>
          </div>


        </form>
      </div>
    </div>
  );
}

export default Form;
