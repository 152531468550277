// src/components/LottieAnimation.js
import React from 'react';
import Lottie from 'lottie-react';
import animationData from './animation.json'; // Save your JSON data as animation.json in the same folder

const LottieAnimation = () => {
  return (
    <div style={{ width: 600, height: 600 }}>
      <Lottie animationData={animationData} loop={true} />
    </div>
  );
};

export default LottieAnimation;
